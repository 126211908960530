import { ArrowDownIcon, ArrowUpIcon } from '@radix-ui/react-icons'
import React from 'react'
import { Severity } from '../utils/api-client/user-platform-api-schemas'
import * as styles from './suggested-severity.css.ts'

interface SuggestedSeverityProps {
  severity: Severity | null
  suggestedSeverity: Severity | null
}

export const SuggestedSeverity: React.FC<SuggestedSeverityProps> = ({ severity, suggestedSeverity }) => {
  if (
    severity === suggestedSeverity ||
    severity === null ||
    suggestedSeverity === null ||
    severity === 'unknown' ||
    suggestedSeverity === 'unknown'
  )
    return <p className={styles.placeholder}>-</p>
  const severityIncreased = severityToOrder(severity) < severityToOrder(suggestedSeverity)
  return (
    <div className={styles.container}>
      {severityIncreased ? (
        <ArrowUpIcon className={styles.arrowUp} aria-label="Severity increased." role="img" />
      ) : (
        <ArrowDownIcon className={styles.arrowDown} aria-label="Severity decreased." role="img" />
      )}
      <p className={styles.text}>{`${severityToLabel(severity)} to ${severityToLabel(suggestedSeverity)}`}</p>
    </div>
  )
}

export const severityToOrder = (severity: Severity) => [...Severity.options].reverse().indexOf(severity)

export const severityToLabel = (severity: Severity) => severity.charAt(0).toUpperCase() + severity.slice(1)
