import { CheckIcon, CopyIcon } from '@radix-ui/react-icons'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import * as styles from './copy-button.css.ts'

export const CopyButton = ({ text, label }: { text: string; label: string }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false)
      }, 1600)
    }
    return () => clearTimeout(timeout)
  }, [copied])

  const handleCopy = () => {
    navigator.clipboard.writeText(text)
    setCopied(true)
  }

  return (
    <button className={styles.copyButton} onClick={handleCopy} aria-label={`Copy ${label} to clipboard`}>
      <CopyIcon className={classNames(styles.icon, styles.copyIcon, { [styles.hidden]: copied })} />
      <CheckIcon
        className={classNames(styles.icon, styles.checkIcon, { [styles.visible]: copied })}
        data-testid="check-icon"
      />
    </button>
  )
}
