import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import classNames from 'classnames'
import { Checkbox } from './checkbox'
import * as styles from './dropdown-filter-select.css.ts'
import { OutlineButton } from './outline-button'

type DropdownFilterSelectProps = {
  columnFilters: { id: string; value: string[] }[]
  setColumnFilters?: Function
  toggleAnalysisColumnFilter?: Function
  toggleStatusColumnFilter?: Function
}

export function DropdownFilterSelect({
  columnFilters,
  toggleStatusColumnFilter = () => () => {},
}: DropdownFilterSelectProps) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <OutlineButton
          state={columnFilters.flatMap(filter => filter.value).length > 0 ? 'active' : 'inactive'}
          size="small"
        >
          Filter ({columnFilters.flatMap(filter => filter.value).length})
        </OutlineButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={classNames(styles.dropdownContainer, styles.disableOutlineHover)}
          onCloseAutoFocus={e => e.preventDefault()}
        >
          <DropdownMenu.Group className={styles.group}>
            <DropdownMenu.Label className={styles.label}>Active</DropdownMenu.Label>
            <CheckboxItem
              text={'Enabled'}
              isChecked={() =>
                columnFilters.some(({ id, value }) => id === 'active' && value.some(value => value === 'true'))
              }
              toggleColumnFilter={toggleStatusColumnFilter('true')}
            />
            <CheckboxItem
              text={'Disabled'}
              isChecked={() =>
                columnFilters.some(({ id, value }) => id === 'active' && value.some(value => value === 'false'))
              }
              toggleColumnFilter={toggleStatusColumnFilter('false')}
            />
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

type CheckboxItemProps = {
  text: string
  isChecked: () => boolean
  toggleColumnFilter: Function
}

function CheckboxItem({ text, isChecked, toggleColumnFilter }: CheckboxItemProps) {
  return (
    <DropdownMenu.CheckboxItem
      className={styles.checkboxItem}
      onSelect={event => event.preventDefault()}
      checked={isChecked()}
      onCheckedChange={() => {
        toggleColumnFilter()
      }}
    >
      <Checkbox checked={isChecked()} Indicator={DropdownMenu.ItemIndicator} />
      <span className={styles.checkboxText}>{text}</span>
    </DropdownMenu.CheckboxItem>
  )
}
