import * as Dialog from '@radix-ui/react-dialog'
import { Cross2Icon } from '@radix-ui/react-icons'
import { getSuggestedStatusLabel } from '../routes/analysis-details-page'
import { Finding } from '../utils/api-client/user-platform-api-schemas'
import { Theme } from '../utils/higher-order-components/with-theme'
import * as styles from './finding-details-drawer.css'
import { Markdown } from './markdown'

export const FindingDetailsDrawerPure: React.FC<{
  finding: Finding
  markdown: string
  theme: Theme
  handleClose?: () => void
}> = ({ finding, markdown, theme, handleClose = () => {} }) => {
  return (
    <Dialog.Root open={true} modal={false}>
      <Dialog.Portal>
        <Dialog.Content className={styles.content} aria-describedby={undefined}>
          <div className={styles.header}>
            <Dialog.Title className={styles.title}>{finding.title}</Dialog.Title>
            <div className={styles.metadata}>
              <div className={styles.metadataItem}>
                <label id={`rule-label-${finding.id}`} className={styles.metadataItemLabel}>
                  Rule
                </label>
                <span className={styles.metadataItemValue} aria-labelledby={`rule-label-${finding.id}`}>
                  {finding.rule}
                </span>
              </div>
              <div className={styles.metadataItem}>
                <label id={`finding-label-${finding.id}`} className={styles.metadataItemLabel}>
                  Finding
                </label>
                <span className={styles.metadataItemValue} aria-labelledby={`finding-label-${finding.id}`}>
                  {finding.id}
                </span>
              </div>
              <div className={styles.metadataItem}>
                <label id={`severity-label-${finding.id}`} className={styles.metadataItemLabel}>
                  Severity
                </label>
                <span
                  className={styles.metadataItemValue}
                  aria-labelledby={`severity-label-${finding.id}`}
                  style={{ textTransform: 'capitalize' }}
                >
                  {finding.severity}
                </span>
              </div>
              <div className={styles.metadataItem}>
                <label id={`result-label-${finding.id}`} className={styles.metadataItemLabel}>
                  Result
                </label>
                <span className={styles.metadataItemValue} aria-labelledby={`result-label-${finding.id}`}>
                  {finding.triaged_finding_response ? 'Triaged' : 'Not Triaged'}
                </span>
              </div>
            </div>
            {finding.triaged_finding_response && (
              <>
                <div className={styles.suggestedChangesTitle}>Suggested Changes Based On Risk Factors:</div>
                <div className={styles.metadataItem}>
                  <label id={`suggested-status-label-${finding.id}`} className={styles.suggestedChangeLabel}>
                    STATUS
                  </label>
                  <span
                    className={styles.suggestedChangeValue}
                    aria-labelledby={`suggested-status-label-${finding.id}`}
                  >
                    {getSuggestedStatusLabel(finding.triaged_finding_response?.suggested_status)}
                  </span>
                </div>
              </>
            )}
            {finding.triaged_finding_response?.suggested_severity && (
              <div className={styles.metadataItem}>
                <label id={`suggested-severity-label-${finding.id}`} className={styles.suggestedChangeLabel}>
                  SEVERITY
                </label>
                <span
                  className={styles.suggestedChangeValue}
                  aria-labelledby={`suggested-severity-label-${finding.id}`}
                  style={{ textTransform: 'capitalize' }}
                >
                  {finding.triaged_finding_response?.suggested_severity}
                </span>
              </div>
            )}
            <Dialog.Close asChild className={styles.closeButton}>
              <button aria-label="Close" onClick={handleClose}>
                <Cross2Icon className={styles.closeButtonIcon} />
              </button>
            </Dialog.Close>
          </div>

          <div className={styles.markdownContainer}>
            <Markdown theme={theme} markdown={reformatFindingTriageArticleMarkdown(markdown)} />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export const reformatFindingTriageArticleMarkdown = (markdown: string) => {
  return markdown
    .replace(/<details>/g, '')
    .replace(/<\/details>/g, '')
    .replace(/\s*<i>Suggestion:.*?<\/i><br\/>\s*/g, '')
    .split('\n')
    .map(line => line.trim())
    .join('\n')
    .trim()
}
