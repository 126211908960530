import { GitMergeIcon, MarkGithubIcon } from '@primer/octicons-react'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { match } from 'ts-pattern'
import { FindingDetailsDrawerPure } from '../components/finding-details-drawer.tsx'
import { FullPageSpinner } from '../components/full-page-spinner.tsx'
import { PreviewFixDrawer, PreviewFixDrawerPure } from '../components/preview-fix-drawer'
import { SeverityBadge } from '../components/severity-badge'
import { Spinner } from '../components/spinner'
import { SuggestedSeverity } from '../components/suggested-severity.tsx'
import { Pagination, Table } from '../components/table'
import { utilities } from '../main.css.ts'
import { useGetFindingArticle, useGetFindings, useGetScanAnalysis } from '../utils/api-client/user-platform-api-hooks'
import {
  FindingsWithFixId,
  FindingWithFixId,
  RepositoryType,
  ScanAnalysis,
  Tool,
  TriagedFindingResponse,
} from '../utils/api-client/user-platform-api-schemas'
import { Theme, useTheme } from '../utils/higher-order-components/with-theme'
import { useAddToast } from '../utils/higher-order-components/with-toasts'
import * as styles from './analysis-details-page.css'

export function AnalysisDetailsPage() {
  const { analysisId, scanId } = useParams()
  if (!analysisId || !scanId) {
    throw new Error('Missing required parameters: installationId, repositoryId, analysisId, or scanId')
  }

  const { theme } = useTheme()
  const { handleAddToastWithTimeout } = useAddToast()
  const { data: scanAnalysis } = useGetScanAnalysis({ analysisId, scanId })

  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  })
  const { data: page } = useGetFindings({
    analysisId,
    scanId,
    pageNumber: pagination.pageIndex,
    pageSize: pagination.pageSize,
  })
  const findings = page?.items

  const [selectedFindingId, setSelectedFindingId] = useState<string | undefined>(undefined)
  const {
    data: findingArticle,
    error,
    isError: isFindingArticleError,
  } = useGetFindingArticle({
    analysisId,
    scanId,
    findingId: selectedFindingId!,
    enabled: !!selectedFindingId,
  })
  const selectedFinding = findings?.find(finding => finding.id === selectedFindingId)
  const selectedFindingMarkdown = findingArticle
  useEffect(() => {
    if (isFindingArticleError) {
      setSelectedFindingId(undefined)
      handleAddToastWithTimeout({
        message: <p>Failed loading finding triage article: {error?.message ?? 'Unknown error'}</p>,
        variant: 'error',
      })
      if (error.bodyAsText) {
        handleAddToastWithTimeout({
          message: <pre>{error.bodyAsText}</pre>,
          variant: 'error',
        })
      }
    }
  }, [isFindingArticleError, error, handleAddToastWithTimeout])

  return (
    <AnalysisDetailsPagePure
      theme={theme}
      scanAnalysis={scanAnalysis}
      findings={findings}
      getTotalFindings={() => page?.total}
      findingsTable={
        <FindingsTablePure
          pagination={pagination}
          setPagination={setPagination}
          findings={findings}
          getTotalFindings={() => page?.total}
          previewFixDrawer={({ findingId }) => (
            <PreviewFixDrawer
              analysisId={analysisId}
              scanId={scanId}
              findingId={findingId}
              handleAddToastWithTimeout={handleAddToastWithTimeout}
            />
          )}
          selectedFinding={selectedFinding}
          selectedFindingMarkdown={selectedFindingMarkdown}
          setSelectedFindingId={setSelectedFindingId}
          theme={theme}
        />
      }
    />
  )
}

export function AnalysisDetailsPagePure({
  theme = 'dark',
  scanAnalysis,
  findings,
  getFixesCount = (findings?: FindingsWithFixId) =>
    findings?.filter(finding => finding.fix_id !== null && finding.fix_id !== undefined).length,
  getTotalFindings = (findings?: FindingsWithFixId) => findings?.length,
  findingsTable = <FindingsTableWithPaginationUIState findings={findings} theme={theme} />,
}: {
  theme?: ReturnType<typeof useTheme>['theme']
  scanAnalysis?: ScanAnalysis
  findings?: FindingsWithFixId
  getFixesCount?: (findings?: FindingsWithFixId) => number | undefined
  getTotalFindings?: (findings?: FindingsWithFixId) => number | undefined
  findingsTable?: ReturnType<typeof FindingsTablePure>
}) {
  const fixesCount = getFixesCount(findings)
  const totalFindings = getTotalFindings(findings)

  return (
    <div className={styles.analysisDetailsContainer}>
      <header className={styles.analysisDetailsHeader} role="banner">
        {scanAnalysis ? (
          <ScanInfo scanAnalysis={scanAnalysis} theme={theme} />
        ) : (
          <Spinner label="Loading scan analysis..." />
        )}
        <section className={styles.repoInfoContainer} aria-labelledby="repo-info-header">
          <h2 id="repo-info-header" className={utilities.visuallyHidden}>
            Repository Information
          </h2>
          {scanAnalysis?.repository_display_name ? (
            <RepositoryInformation
              type={scanAnalysis.repository_type}
              displayRepositoryName={repositoryNameToDisplay(scanAnalysis.repository_type)(
                scanAnalysis.repository_display_name
              )}
              displayRepositoryIcon={repositoryIconToDisplay(scanAnalysis.repository_type)}
            />
          ) : (
            <Spinner label="Loading user and repository details..." />
          )}
          {findings && typeof fixesCount === 'number' && typeof totalFindings === 'number' && scanAnalysis?.tool && (
            <FindingsBar fixesCount={fixesCount} totalFindings={totalFindings} tool={scanAnalysis.tool} />
          )}
        </section>
        <section className={styles.snykLinkContainer} role="complementary" aria-labelledby="metrics-header">
          <h2 id="metrics-header" className={utilities.visuallyHidden}>
            Metrics and External Link
          </h2>
          {scanAnalysis?.html_url && (
            <a href={scanAnalysis?.html_url} target="_blank" rel="noopener noreferrer" className={styles.snykLink}>
              View in {mapToolToLogoAndName(scanAnalysis.tool, theme).name}
              <ExternalLinkIcon className={styles.externalLinkIcon} />
            </a>
          )}
          {findings && typeof fixesCount === 'number' && typeof totalFindings === 'number' && (
            <FindingsMetrics fixesCount={fixesCount} totalFindings={totalFindings} />
          )}
        </section>
      </header>
      {findingsTable}
    </div>
  )
}

function FindingsTableWithPaginationUIState({ findings, theme }: { findings?: FindingsWithFixId; theme: Theme }) {
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  })

  return <FindingsTablePure pagination={pagination} setPagination={setPagination} findings={findings} theme={theme} />
}

function FindingsTablePure({
  pagination,
  setPagination,
  findings,
  getTotalFindings = (findings?: FindingsWithFixId) => findings?.length,
  previewFixDrawer = () => (
    <PreviewFixDrawerPure
      changesets={[
        {
          codemod_id: 'appscan:java/improper-exception-handling',
          path: 'src/main/java/com/hcl/appscan/sdk/http/HttpsClient.java',
          diff: '--- HttpsClient.java\n+++ HttpsClient.java\n@@ -281,7 +281,6 @@\n HttpsURLConnection conn = null;\n conn = (HttpsURLConnection) requestURL.openConnection();\n conn.setRequestMethod(method.name());\n-conn.setReadTimeout(0);\n \n // HTTP headers\n if (headerProperties != null) {',
          description:
            'This change ensures that HTTP response header values can\'t contain newline characters, leaving you vulnerable to HTTP response splitting and other attacks.\n\nIf malicious users can get newline characters into an HTTP response header, they can inject and forge new header values that look like they came from the server, and trick web gateways, proxies, and browsers. This leads to vulnerabilities like Cross-site Scripting (XSS), HTTP response splitting, and more attacks from there.\n\nOur change simply makes sure that if the string passed to be a new response header value is non-null, all the newline characters (CR and LF) will be removed:\n```diff\n+ import io.github.pixee.security.Newlines;\n  ...\n  String orderId = getUserOrderId();\n- response.setHeader("X-Acme-Order-ID", orderId);\n+ response.setHeader("X-Acme-Order-ID", Newlines.stripAll(orderId));\n```\n\nNote: Many modern application servers will sanitize these values, but it\'s almost never specified in documentation, and thus there is little guarantee against regression. Given that, we still recommend this practice.\n',
        },
        {
          codemod_id: 'appscan:java/improper-exception-handling',
          path: 'src/main/java/com/hcl/appscan/sdk/scanners/sast/xml/DOMWriter.java',
          diff: '--- DOMWriter.java\n+++ DOMWriter.java\n@@ -239,8 +239,7 @@\n try {\n m_stream.close();\n } catch (IOException e) {\n-e.printStackTrace();\n-}\n+}\n m_stream = null;\n }\n }',
          description:
            'This change ensures that HTTP response header values can\'t contain newline characters, leaving you vulnerable to HTTP response splitting and other attacks.\n\nIf malicious users can get newline characters into an HTTP response header, they can inject and forge new header values that look like they came from the server, and trick web gateways, proxies, and browsers. This leads to vulnerabilities like Cross-site Scripting (XSS), HTTP response splitting, and more attacks from there.\n\nOur change simply makes sure that if the string passed to be a new response header value is non-null, all the newline characters (CR and LF) will be removed:\n```diff\n+ import io.github.pixee.security.Newlines;\n  ...\n  String orderId = getUserOrderId();\n- response.setHeader("X-Acme-Order-ID", orderId);\n+ response.setHeader("X-Acme-Order-ID", Newlines.stripAll(orderId));\n```\n\nNote: Many modern application servers will sanitize these values, but it\'s almost never specified in documentation, and thus there is little guarantee against regression. Given that, we still recommend this practice.\n',
        },
        {
          codemod_id: 'appscan:java/improper-exception-handling',
          path: 'src/main/java/com/hcl/appscan/sdk/scanners/sast/xml/XmlWriter.java',
          diff: '--- XmlWriter.java\n+++ XmlWriter.java\n@@ -43,10 +43,8 @@\n try {\n initialize(directory);\n } catch (TransformerConfigurationException e) {\n-e.printStackTrace();\n-} catch (ParserConfigurationException e) {\n-e.printStackTrace();\n-}\n+} catch (ParserConfigurationException e) {\n+}\n m_config = new DOMWriter(directory, m_configFileName, m_builder);\n }\n',
          description:
            'This change ensures that HTTP response header values can\'t contain newline characters, leaving you vulnerable to HTTP response splitting and other attacks.\n\nIf malicious users can get newline characters into an HTTP response header, they can inject and forge new header values that look like they came from the server, and trick web gateways, proxies, and browsers. This leads to vulnerabilities like Cross-site Scripting (XSS), HTTP response splitting, and more attacks from there.\n\nOur change simply makes sure that if the string passed to be a new response header value is non-null, all the newline characters (CR and LF) will be removed:\n```diff\n+ import io.github.pixee.security.Newlines;\n  ...\n  String orderId = getUserOrderId();\n- response.setHeader("X-Acme-Order-ID", orderId);\n+ response.setHeader("X-Acme-Order-ID", Newlines.stripAll(orderId));\n```\n\nNote: Many modern application servers will sanitize these values, but it\'s almost never specified in documentation, and thus there is little guarantee against regression. Given that, we still recommend this practice.\n',
        },
      ]}
      codemod={{
        codemod_id: 'appscan:java/improper-exception-handling',
        name: '(AppScan) Remediate Improper Handling of Exceptional Conditions',
        description:
          'This change ensures that HTTP response header values can\'t contain newline characters, leaving you vulnerable to HTTP response splitting and other attacks.\n\nIf malicious users can get newline characters into an HTTP response header, they can inject and forge new header values that look like they came from the server, and trick web gateways, proxies, and browsers. This leads to vulnerabilities like Cross-site Scripting (XSS), HTTP response splitting, and more attacks from there.\n\nOur change simply makes sure that if the string passed to be a new response header value is non-null, all the newline characters (CR and LF) will be removed:\n```diff\n+ import io.github.pixee.security.Newlines;\n  ...\n  String orderId = getUserOrderId();\n- response.setHeader("X-Acme-Order-ID", orderId);\n+ response.setHeader("X-Acme-Order-ID", Newlines.stripAll(orderId));\n```\n\nNote: Many modern application servers will sanitize these values, but it\'s almost never specified in documentation, and thus there is little guarantee against regression. Given that, we still recommend this practice.\n',
      }}
    />
  ),
  selectedFinding,
  selectedFindingMarkdown,
  setSelectedFindingId = () => {},
  theme,
}: {
  pagination: Pagination
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
  findings?: FindingsWithFixId
  getTotalFindings?: (findings?: FindingsWithFixId) => number | undefined
  previewFixDrawer?: (props: { findingId: string }) => ReturnType<typeof PreviewFixDrawer>
  selectedFinding?: FindingWithFixId
  selectedFindingMarkdown?: string
  setSelectedFindingId?: React.Dispatch<React.SetStateAction<string | undefined>>
  theme: Theme
}) {
  const totalFindings = getTotalFindings(findings)

  const columns = useMemo(
    () => [
      {
        accessorKey: 'severity',
        meta: { width: '5%' },
        header: () => <span className={styles.header}>SEVERITY</span>,
        cell: ({ row }) => (
          <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <SeverityBadge variant={row.original.severity} />
            </div>
          </SelectableFindingCell>
        ),
      },
      {
        accessorKey: 'title',
        meta: { width: '26%' },
        header: () => (
          <span className={styles.header} style={{ justifyContent: 'flex-start' }}>
            FINDING
          </span>
        ),
        cell: ({ row }) => (
          <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
            <p className={styles.findingTitle}>
              {row.original.title && row.original.title.length > 52
                ? row.original.title.slice(0, 49) + '...'
                : (row.original.title ?? 'No title')}
            </p>
            <p className={styles.findingRule}>{row.original.rule}</p>
          </SelectableFindingCell>
        ),
      },
      {
        accessorKey: 'suggestedStatus',
        meta: { width: '13%' },
        header: () => <span className={styles.header}>SUGGESTED STATUS</span>,
        cell: ({ row }) => {
          if (row.original.triaged_finding_response && row.original.triaged_finding_response.suggested_status) {
            return (
              <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
                <span className={styles.suggestedStatusCell[row.original.triaged_finding_response.suggested_status]}>
                  {getSuggestedStatusLabel(row.original.triaged_finding_response.suggested_status)}
                </span>
              </SelectableFindingCell>
            )
          }

          return (
            <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
              <span className={styles.suggestedStatusCell.null}>-</span>
            </SelectableFindingCell>
          )
        },
      },
      {
        accessorKey: 'severityUpdate',
        meta: { width: '13%' },
        header: () => <span className={styles.header}>SEVERITY UPDATE</span>,
        cell: ({ row }) => (
          <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
            <SuggestedSeverity
              severity={row.original.severity}
              suggestedSeverity={row.original.triaged_finding_response?.suggested_severity ?? null}
            />
          </SelectableFindingCell>
        ),
      },
      {
        accessorKey: 'summary',
        meta: { width: '16%' },
        header: () => <span className={styles.header}>ANALYSIS</span>,
        cell: ({ row }) =>
          row.original.triaged_finding_response?.summary ? (
            <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
              <span className={styles.analysisCell}>
                {row.original.triaged_finding_response.summary.length > 30
                  ? row.original.triaged_finding_response.summary.slice(0, 30) + '...'
                  : row.original.triaged_finding_response.summary}
              </span>
            </SelectableFindingCell>
          ) : (
            <SelectableFindingCell handleClick={() => setSelectedFindingId(row.original.id)}>
              <span className={styles.placeholderCell}>-</span>
            </SelectableFindingCell>
          ),
      },
      {
        accessorKey: 'fix',
        meta: { width: '14%' },
        header: () => (
          <div className={styles.header} style={{ justifyContent: 'flex-start' }}>
            <span style={{ width: '54%', display: 'flex', justifyContent: 'center' }}>FIX</span>
          </div>
        ),
        cell: ({ row }) =>
          row.original.fix_id ? (
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>
              {previewFixDrawer({ findingId: row.original.id })}
            </div>
          ) : (
            <div className={styles.placeholderCell} style={{ justifyContent: 'flex-start' }}>
              <span style={{ width: '54%', display: 'flex', justifyContent: 'center' }}>-</span>
            </div>
          ),
      },
    ],
    []
  )

  const rowSelection = useMemo(() => {
    if (!selectedFinding) {
      return {}
    }

    return {
      [selectedFinding.id]: true,
    }
  }, [selectedFinding])

  const table = useReactTable<FindingWithFixId>({
    data: findings ?? [],
    getRowId: finding => finding.id,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
    },
  })

  return (
    <>
      {selectedFinding && !selectedFindingMarkdown && <FullPageSpinner label="Loading finding details drawer" />}
      {selectedFinding && selectedFindingMarkdown && (
        <FindingDetailsDrawerPure
          finding={selectedFinding}
          markdown={selectedFindingMarkdown}
          handleClose={() => setSelectedFindingId(undefined)}
          theme={theme}
        />
      )}
      <Table
        table={table}
        isLoading={findings === undefined}
        selectable={true}
        pagination={pagination}
        setPagination={setPagination}
        tableLabel="Findings"
        rowCount={totalFindings}
      />
    </>
  )
}

const SelectableFindingCell = ({ children, handleClick }: { children: React.ReactNode; handleClick: () => void }) => (
  <div onClick={handleClick} data-testid="selectable-finding-cell">
    {children}
  </div>
)

export const mapToolToLogoAndName = (tool: Tool, mode: 'light' | 'dark') => {
  const suffix = mode === 'light' ? 'Lt' : 'Dk'
  return match(tool)
    .with('APPSCAN', () => ({ name: 'AppScan', logoHref: '/SastToolVisualAssets/AppScan-Icon.png' }))
    .with('CHECKMARX', () => ({ name: 'Checkmarx', logoHref: '/SastToolVisualAssets/Checkmarx-Icon.svg' }))
    .with('CODEQL', () => ({ name: 'CodeQL', logoHref: '/SastToolVisualAssets/CodeQL-Logo.svg' }))
    .with('CONTRAST', () => ({ name: 'Contrast', logoHref: `/SastToolVisualAssets/Contrast-Icon-${suffix}.svg` }))
    .with('DEFECT_DOJO', () => ({ name: 'DefectDojo', logoHref: '/SastToolVisualAssets/DefectDojo-Icon.svg' }))
    .with('PIXEE', () => ({ name: 'Pixee', logoHref: '/SastToolVisualAssets/Pixee-Icon.svg' }))
    .with('SEMGREP', () => ({ name: 'Semgrep', logoHref: '/SastToolVisualAssets/Semgrep-Icon.svg' }))
    .with('SNYK', () => ({ name: 'Snyk', logoHref: `/SastToolVisualAssets/Snyk-Icon-${suffix}.svg` }))
    .with('SONAR', () => ({ name: 'SonarQube', logoHref: `/SastToolVisualAssets/Sonar-Icon-${suffix}.svg` }))
    .exhaustive()
}

const ScanInfo = ({ scanAnalysis, theme }: { scanAnalysis: ScanAnalysis; theme: 'light' | 'dark' }) => {
  const { name, logoHref } = mapToolToLogoAndName(scanAnalysis.tool, theme)
  const scanDate = DateTime.fromISO(scanAnalysis.imported_at)

  return (
    <div className={styles.scanInfoContainer}>
      <h2 className={utilities.visuallyHidden}>Scan Information</h2>
      <img src={logoHref} alt={`${name} logo`} className={styles.toolLogo} />
      <div className={styles.dateContainer}>
        <p className={styles.dateText}>{scanDate.toFormat('M/d/yy')}</p>
        <p className={styles.timeText}>{scanDate.toFormat('hh:mm:ss a')}</p>
      </div>
      <p className={styles.branchTag} title={scanAnalysis.branch ?? ''}>
        {scanAnalysis.branch && scanAnalysis.branch.length > 25
          ? `${scanAnalysis.branch.slice(0, 22)}...`
          : (scanAnalysis.branch ?? scanAnalysis.sha)}
      </p>
    </div>
  )
}

type repositoryDisplayName = { owner: string; name: string } | { name: string }
type DisplayRepositoryName = () => repositoryDisplayName
type RepositoryNameToDisplay = (repositoryName: string) => DisplayRepositoryName
type RepositoryNameToDisplayWithType = (type: RepositoryType) => RepositoryNameToDisplay
export const repositoryNameToDisplay: RepositoryNameToDisplayWithType = type => repositoryName => () => {
  if (type === 'github') {
    const [owner, name] = repositoryName.split('/')
    invariant(owner, 'Owner is required for GitHub repository names')
    invariant(name, 'Name is required for GitHub repository names')
    return { owner, name }
  }

  const name = repositoryName
  invariant(name, 'Name is required for generic Git repository names')
  return { name }
}

type DisplayRepositoryIcon = () => typeof MarkGithubIcon | typeof GitMergeIcon
type RepositoryIconToDisplayWithType = (type: RepositoryType) => DisplayRepositoryIcon
export const repositoryIconToDisplay: RepositoryIconToDisplayWithType = type => () => {
  return type === 'github'
    ? () => <MarkGithubIcon aria-label="GitHub icon" className={styles.scmIcon} />
    : () => <GitMergeIcon aria-label="Git icon" className={styles.scmIcon} />
}

const RepositoryInformation: React.FC<{
  type: RepositoryType
  displayRepositoryName: DisplayRepositoryName
  displayRepositoryIcon: DisplayRepositoryIcon
}> = ({ type, displayRepositoryName, displayRepositoryIcon }) => {
  const Icon = displayRepositoryIcon()
  const displayName = displayRepositoryName()

  return (
    <>
      {'owner' in displayName && <p className={styles.accountLoginText}>{displayName.owner}</p>}
      <div className={styles.repositoryNameContainer}>
        <Icon />
        <p className={styles.repositoryName[type]}>{displayName.name}</p>
      </div>
    </>
  )
}

const FindingsBar = ({
  fixesCount,
  totalFindings,
  tool,
}: {
  fixesCount: number
  totalFindings: number
  tool: Tool
}) => (
  <div
    role="img"
    aria-label={`${fixesCount} out of ${totalFindings} findings had available fixes`}
    className={styles.fixesContainer}
  >
    <div className={styles.bar}>
      {fixesCount === 0 ? (
        <div
          className={classNames(styles.barFilled[tool], styles.barEmpty, styles.roundedBar)}
          style={{ width: '100%' }}
        />
      ) : fixesCount === totalFindings ? (
        <div className={classNames(styles.barFilled[tool], styles.roundedBar)} style={{ width: '100%' }} />
      ) : (
        <>
          <div className={styles.barFilled[tool]} style={{ width: `${(fixesCount / totalFindings) * 100}%` }} />
          <div
            className={classNames(styles.barFilled[tool], styles.barEmpty)}
            style={{ width: `${((totalFindings - fixesCount) / totalFindings) * 100}%` }}
          />
        </>
      )}
    </div>
    <p className={styles.fixesText}>
      {fixesCount}/{totalFindings} <span className={styles.fixesTextLabel}>findings had available fixes</span>
    </p>
  </div>
)

const FindingsMetrics = ({ fixesCount, totalFindings }: { fixesCount: number; totalFindings: number }) => (
  <div className={styles.metricsContainer} role="group" aria-label="Findings Metrics">
    <output id="findings-count" className={styles.metricValue}>
      {totalFindings}
    </output>
    <label htmlFor="findings-count" className={styles.metricLabel}>
      findings
    </label>
    <output id="fix-coverage" className={styles.metricValue}>
      {totalFindings === 0 ? '0%' : `${Math.round((fixesCount / totalFindings) * 100)}%`}
    </output>
    <label htmlFor="fix-coverage" className={styles.metricLabel}>
      fix coverage
    </label>
  </div>
)

export const getSuggestedStatusLabel = (status: TriagedFindingResponse['suggested_status']) => {
  return match(status)
    .with('wont_fix', () => "Won't fix")
    .with('false_positive', () => 'False positive')
    .with('true_positive', () => 'True positive')
    .with('suspicious', () => 'Suspicious')
    .with(null, () => '-')
    .exhaustive()
}
