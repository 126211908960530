import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons'
import classNames from 'classnames'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { typography } from '../main.css.ts'
import { useGetUser } from '../utils/api-client/user-platform-api-hooks'
import { useTheme } from '../utils/higher-order-components/with-theme'
import { useAddToast } from '../utils/higher-order-components/with-toasts'
import { useEnvironmentData } from '../utils/hooks/use-environment-data'
import { CodemodPreferenceConfigurationDialog } from './codemod-preference-configuration-dialog'
import * as styles from './navigation-bar.css.ts'
import DarkMode from './svg/dark-mode'
import LightMode from './svg/light-mode'
import QuestionFill from './svg/question-fill'
import QuestionFillBlack from './svg/question-fill-black'

export function NavigationBar({
  isCheckInstallationsBreadcrumb,
  setIsCheckInstallationsBreadcrumb,
}: Readonly<{
  isCheckInstallationsBreadcrumb: boolean
  setIsCheckInstallationsBreadcrumb: React.Dispatch<React.SetStateAction<boolean>>
}>) {
  const { theme, toggleTheme } = useTheme()
  const [isCodemodPreferenceConfigurationEnabled, setIsCodemodPreferenceConfigurationEnabled] = useState<boolean>(false)
  const { data: user } = useGetUser()
  const environmentData = useEnvironmentData()
  const { pathname } = useLocation()
  const { handleAddToastWithTimeout } = useAddToast()

  return (
    <nav className={styles.navbar} aria-label="Main Navigation">
      <div className={styles.leftGroup}>
        <Link to="/">
          <div className={styles.navBarBrand}>
            <img src={environmentData.logoIconHref} alt={environmentData.logoIconAlt} height={32} />
            {environmentData.name === 'ntt' ? '' : ' Pixee'}
          </div>
        </Link>
        {user && (
          <Link to="/repositories" className={styles.navLink} onClick={() => setIsCheckInstallationsBreadcrumb(false)}>
            Repositories {isCheckInstallationsBreadcrumb && <span className={styles.breadcrumb} />}
          </Link>
        )}
        {pathname.includes('/analysis/') && (
          <Link to="/analysis-details" className={classNames(styles.navLink, styles.activeNavLink)}>
            Scan analysis
          </Link>
        )}
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '10px' }}>
        <a href="https://docs.pixee.ai" target="_blank" style={{ width: '24px', height: '24px', lineHeight: '0' }}>
          {theme === 'dark' ? <QuestionFill /> : <QuestionFillBlack />}
        </a>

        <button onClick={toggleTheme} style={{ cursor: 'pointer' }} aria-label="Toggle theme" title="Toggle theme">
          {theme == 'light' ? <LightMode /> : <DarkMode />}
        </button>

        {user && (
          <DropdownMenu.Root>
            <DropdownMenu.Trigger className={styles.dropdownMenuTrigger}>
              <img
                src={user.avatar_url}
                style={{ borderRadius: '50%', marginRight: '8px' }}
                width={28}
                height={28}
                alt={`${user.login}'s user profile image`}
              />
              <TriangleUpIcon className={styles.triangleUpIcon} />
              <TriangleDownIcon className={styles.triangleDownIcon} />
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
              <DropdownMenu.Content className={styles.dropdownMenuContent} sideOffset={17}>
                <DropdownMenu.Label>
                  <p className={typography.paragraph} style={{ margin: 0 }}>
                    Signed in as{' '}
                  </p>
                  <p className={typography.h6} style={{ margin: 0 }}>
                    {user.login.length > 20 ? `${user.login.slice(0, 20 - 3)}...` : user.login}
                  </p>
                </DropdownMenu.Label>
                <DropdownMenu.Separator className={styles.dropdownMenuSeparator} />
                <DropdownMenu.Item
                  className={styles.dropdownMenuItem}
                  onClick={() => {
                    setIsCodemodPreferenceConfigurationEnabled(true)
                  }}
                >
                  View / change default preference
                </DropdownMenu.Item>
                <DropdownMenu.Separator className={styles.dropdownMenuSeparator} />
                <DropdownMenu.Item className={styles.dropdownMenuItem} asChild>
                  <a href="/api/auth/logout" style={{ color: 'unset' }}>
                    Sign Out
                  </a>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        )}
        {user && isCodemodPreferenceConfigurationEnabled && (
          <CodemodPreferenceConfigurationDialog
            handleCloseConfigurationDialog={() => setIsCodemodPreferenceConfigurationEnabled(false)}
            handleAddToastWithTimeout={handleAddToastWithTimeout}
          />
        )}
      </div>
    </nav>
  )
}
