import CloseIcon from './svg/close-icon.svg?react'
import type { ToastVariants } from './toast.css.ts'
import * as styles from './toast.css.ts'

interface ToastProps {
  /**
   * The content to be displayed in the toast.
   * This must be a JSX element, like `<p><b>400:</b> Error message</p>` or `<p>Here is something you may want to know</p>.
   * Plain strings are not allowed.
   */
  children: React.ReactElement | string
  variant: ToastVariants
  handleClose?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /**
   * Custom emoji icon that will overwrite the default emoji.
   * Provide a string representing the emoji, e.g., "😊".
   * When this prop is provided, it replaces the default icon set by the 'variant' prop.
   */
  emojiIcon?: string
  /**
   * Optionally adds a 'Learn More'
   */
  linkHref?: string
}

export const Toast = ({ children, variant, handleClose, emojiIcon, linkHref }: ToastProps) => {
  return (
    <div className={styles.toast({ variant })} role="alert" aria-live="assertive" aria-atomic="true">
      <div className={styles.toastBody}>
        <span>{emojiIcon ? emojiIcon : defaultEmoji(variant)}</span>
        <div className={styles.toastContent}>{children}</div>
        {linkHref && (
          <a href={linkHref} target="_blank" className={styles.link({ variant })}>
            Learn more
          </a>
        )}
        {handleClose && (
          <button type="button" onClick={handleClose} aria-label="Close">
            <CloseIcon className={styles.closeIcon({ variant })} />
          </button>
        )}
      </div>
    </div>
  )
}

const defaultEmoji = (variant: ToastVariants): string => {
  switch (variant) {
    case 'success':
      return '✔'
    case 'warning':
      return '‼️️'
    case 'error':
      return '🆇'
    case 'info':
      return '💡'
  }
}
