import { PropsWithChildren } from 'react'
import { Footer } from '../../components/footer'
import { NavigationBar } from '../../components/navigation-bar'
import { NavigationBarStub } from '../../components/navigation-bar-stub'
import { useOutletData } from '../../root.tsx'
import * as styles from './with-layout.css.ts'

export const WithLayout = (props: PropsWithChildren) => {
  const { isCheckInstallationsBreadcrumb, setIsCheckInstallationsBreadcrumb } = useOutletData()

  return (
    <>
      <NavigationBar
        isCheckInstallationsBreadcrumb={isCheckInstallationsBreadcrumb}
        setIsCheckInstallationsBreadcrumb={setIsCheckInstallationsBreadcrumb}
      />
      <div className={styles.siteContainer}>
        <main className={styles.main}>{props.children}</main>
        <Footer />
      </div>
    </>
  )
}

export const WithStubLayout = (props: PropsWithChildren) => {
  return (
    <>
      <NavigationBarStub />
      <div className={styles.siteContainer}>
        <main className={styles.main}>{props.children}</main>
        <Footer />
      </div>
    </>
  )
}
