import classNames from 'classnames'
import { typography } from '../main.css.ts'
import { useTheme } from '../utils/higher-order-components/with-theme'
import './no-installations-component.css'
import DotLine from './svg/no-installations-dot-line'

export function NoInstallationsComponent() {
  const { theme } = useTheme()

  return (
    <div style={{ overflow: 'hidden', width: '100%' }}>
      <div id={theme} className="no-installations">
        <div className="modal-frame-no-installations">
          <div className="heading-frame-no-installations" style={{ alignItems: 'flex-start' }}>
            <p className={classNames('heading-text-no-installations', typography.h6)}>No installations found</p>
          </div>
          <p className={classNames('paragraph-no-installations', typography.paragraph)}>
            It looks like you don’t have any complete installations. It could be that pixeebot was uninstalled by an
            administrator, or a request is pending approval. We suggest you check GitHub and then check back here. Or,
            go ahead and{' '}
            <a className="docs-link" href={`https://github.com/apps/pixeebot/installations/new`} target="_blank">
              start a new installation.
            </a>
          </p>
        </div>
      </div>
      <div className="pixee-white">
        <img
          src={theme === 'dark' ? 'pixee-white.png' : 'pixee-black.png'}
          alt="Pixee logo"
          style={{ width: '88px', height: 'auto' }}
        />
      </div>
      <div className="dot-line">
        <DotLine theme={theme} />
      </div>
      <div id={theme} className="background-stardust">
        {theme === 'dark' && (
          <img src="stardust.png" style={{ width: '50%', height: '100%' }} alt="Stardust background" />
        )}
      </div>
    </div>
  )
}
