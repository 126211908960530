import { match } from 'ts-pattern'
import { Severity } from '../utils/api-client/user-platform-api-schemas.ts'
import * as styles from './severity-badge.css.ts'

type SeverityBadgeProps = {
  variant: Severity | null
  style?: any
}

export function SeverityBadge({ variant, ...props }: SeverityBadgeProps) {
  const text = match(variant)
    .with('critical', () => 'Critical')
    .with('high', () => 'High')
    .with('medium', () => 'Medium')
    .with('low', () => 'Low')
    .with('info', () => 'Info')
    .with('unknown', () => 'Unknown')
    .with(null, () => 'Unknown')
    .exhaustive()

  return (
    <span className={styles.badge[variant ?? 'unknown']} {...props}>
      {text}
    </span>
  )
}
