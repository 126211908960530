import { InfoCircledIcon } from '@radix-ui/react-icons'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import classNames from 'classnames'
import { useState } from 'react'
import * as styles from './tooltip.css.ts'

interface TooltipProps {
  children: React.ReactNode
  iconClassName?: string
}

export const Tooltip = ({ children, iconClassName }: TooltipProps) => {
  return (
    <TooltipWithClickToOpen>
      <RadixTooltip.Trigger>
        <InfoCircledIcon className={classNames(styles.infoIcon, iconClassName)} />
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content className={styles.tooltip} sideOffset={3}>
          {children}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </TooltipWithClickToOpen>
  )
}

// Necessary to enable tooltip on click on mobile since Radix designers won't add this functionality
// https://github.com/radix-ui/primitives/issues/955
export default function TooltipWithClickToOpen({ children }) {
  const [open, setOpen] = useState(false)

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root open={open} delayDuration={0} onOpenChange={setOpen}>
        <span
          onClick={() => setOpen(true)}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center ' }}
        >
          {children}
        </span>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}
