import classNames from 'classnames'
import { typography } from '../main.css.ts'
import { useTheme } from '../utils/higher-order-components/with-theme'
import './footer.css'

export const Footer = () => {
  const { theme } = useTheme()

  return (
    <footer className="footer mt-auto footer-container" id={theme}>
      <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
        <p className={classNames('col-md-5 mb-0', typography.small, 'footer-trade-mark-para')}>
          <span className={classNames(typography.smallBold)}>© 2023 Pixee Inc.</span> All rights reserved.{' '}
        </p>
        <ul className={classNames('nav', 'col-md-5', 'justify-content-end')}>
          <li className="nav-item">
            <a
              href="https://www.pixee.ai/terms"
              target="_blank"
              className={classNames('footer-link', typography.small)}
            >
              Terms of Service
            </a>
          </li>
          <li className="nav-item" style={{ marginLeft: '32px' }}>
            <a
              href="https://www.pixee.ai/privacy"
              target="_blank"
              className={classNames('footer-link', typography.small)}
            >
              Privacy Policy
            </a>
          </li>
          <li className="nav-item" style={{ marginLeft: '32px' }}>
            <a href="mailto:feedback@pixee.ai" target="_blank" className={classNames('footer-link', typography.small)}>
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}
