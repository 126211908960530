import { CodemodHitStatus, PullRequest, PullRequestWithImportance } from '../api-client/user-platform-api-schemas'

const codemodsImportance: Partial<CodemodHitStatus>[] = [
  {
    codemod_id: 'pixee:java/harden-xmlinputfactory',
    name: 'Introduced protections against XXE attacks',
    importance: 'HIGH',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:java/harden-zip-entry-paths',
    name: 'Introduced protections against "zip slip" attacks',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/encode-jsp-scriptlet',
    name: 'Introduced protections against XSS attacks in JSP scriptlets',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/fix-verb-tampering',
    name: 'Introduced protections against verb tampering attacks (authN/authZ bypass)',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/harden-java-deserialization',
    name: 'Introduced protections against deserialization attacks',
    importance: 'HIGH',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:java/secure-random',
    name: 'Introduced protections against predictable RNG abuse',
    importance: 'MEDIUM',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:java/sql-parameterizer',
    name: 'Refactored to use parameterized SQL APIs',
    importance: 'HIGH',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:java/limit-readline',
    name: 'Protect `readLine()` against DoS',
    importance: 'MEDIUM',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:java/validate-jakarta-forward-path',
    name: 'Introduced protections against user-controlled internal request forwarding',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/upgrade-sslengine-tls',
    name: 'Upgraded SSLEngine#setEnabledProtocols() TLS versions to match current best practices',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/upgrade-sslparameters-tls',
    name: 'Upgraded SSLParameters#setProtocols() TLS versions to match current best practices',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/upgrade-sslsocket-tls',
    name: 'Upgraded SSLSocket#setEnabledProtocols() TLS versions to match current best practices',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/upgrade-sslcontext-tls',
    name: 'Upgraded SSLContext#getInstance() TLS versions to match current best practices',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/harden-process-creation',
    name: 'Introduced protections against system command injection',
    importance: 'HIGH',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:java/make-prng-seed-unpredictable',
    name: 'Strengthened cipher seed with more unpredictable value',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/harden-xmldecoder-stream',
    name: 'Hardened XMLDecoder usage to prevent common attacks',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/sandbox-url-creation',
    name: 'Sandboxed URL creation to prevent SSRF attacks',
    importance: 'HIGH',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:java/sanitize-apache-multipart-filename',
    name: 'Sanitized user-provided file names in HTTP multipart uploads',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/sanitize-spring-multipart-filename',
    name: 'Sanitized user-provided file names in HTTP multipart uploads',
    importance: 'HIGH',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:java/strip-http-header-newlines',
    name: 'Introduced protections against HTTP header injection / smuggling attacks',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/harden-xstream',
    name: 'Hardened XStream with a converter to prevent exploitation',
    importance: 'HIGH',
    result: 'FOUND',
  },
  {
    codemod_id: 'codeql:java/missing-jwt-signature-check',
    name: '(CodeQL) Switch JWT calls to versions that enforce signature validity',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'codeql:java/database-resource-leak',
    name: 'Prevent database resource leaks(CodeQL)',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'codeql:java/stack-trace-exposure',
    name: '(CodeQL) Prevent information leak of stack trace details to HTTP responses',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'codeql:java/insecure-cookie',
    name: '(CodeQL) Added secure flag to HTTP cookies',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'codeql:java/output-resource-leak',
    name: '(CodeQL) Prevent resource leaks',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/upgrade-tempfile-to-nio',
    name: 'Modernize and secure temp file creation',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/switch-literal-first',
    name: 'Switch order of literals to prevent NullPointerException',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/prevent-filewriter-leak-with-nio',
    name: 'Prevent file descriptor leak and modernize BufferedWriter creation',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/hql-parameterizer',
    name: 'Refactored to use parameterized HQL APIs',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/disable-dircontext-deserialization',
    name: 'Hardened LDAP call against deserialization attacks',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/move-switch-default-last',
    name: 'Moved default case in switch to last entry',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/verbose-request-mapping',
    name: 'Replaced @RequestMapping annotation with shortcut annotation for requested HTTP Method',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:java/add-clarifying-braces',
    name: 'Add clarifying braces to misleading code',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id:
      'semgrep:java/java.lang.security.audit.overly-permissive-file-permission.overly-permissive-file-permission',
    name: '(Semgrep) Fixed overly permissive file permissions',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/harden-string-parse-to-primitives-s2130',
    name: '(Sonar) Implemented parsing usage when converting Strings to primitives',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/overrides-match-synchronization-s3551',
    name: '(Sonar) Added missing synchronized keyword',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/remove-redundant-variable-creation-s1488',
    name: '(Sonar) Remove redundant variable creation expression when it is only returned/thrown',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/simplify-rest-controller-annotations-s6833',
    name: '(Sonar) Replace `@Controller` with `@RestController` and remove `@ResponseBody` annotations',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/remove-commented-code-s125',
    name: '(Sonar) Removed block of commented-out lines of code',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/remove-unused-private-method-s1144',
    name: '(Sonar) Removed unused private method',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/declare-variable-on-separate-line-s1659',
    name: '(Sonar) Split variable declarations into their own statements',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/remove-unused-local-variable-s1481',
    name: '(Sonar) Removed unused local variable',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/define-constant-for-duplicate-literal-s1192',
    name: '(Sonar) Define a constant for a literal string that is duplicated n times',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/remove-useless-parentheses-s1110',
    name: '(Sonar) Remove useless parentheses',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/avoid-implicit-public-constructor-s1118',
    name: '(Sonar) Set private constructor to hide implicit public constructor',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/replace-stream-collectors-to-list-s6204',
    name: '(Sonar) Replaced `Stream.collect(Collectors.toList())` with `Stream.toList()`',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/add-missing-override-s1161',
    name: '(Sonar) Added missing @Override parameter',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/substitute-replaceAll-s5361',
    name: '(Sonar) Fixed inefficient usage of `String#replaceAll()`',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:java/remove-redundant-static-s2786',
    name: '(Sonar) Removed redundant static flag on enum',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:python/django-json-response-type-S5131',
    name: 'Sonar: Set content type to `application/json` for `django.http.HttpResponse` with JSON data',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:python/django-receiver-on-top-S6552',
    name: 'Sonar: Ensure Django @receiver is the first decorator',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:python/exception-without-raise-S3984',
    name: 'Sonar: Ensure bare exception statements are raised',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:python/fix-assert-tuple-S5905',
    name: 'Sonar: Fix `assert` on Non-Empty Tuple Literal',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:python/flask-json-response-type-S5131',
    name: 'Sonar: Set content type to `application/json` for `flask.make_response` with JSON data',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:python/jwt-decode-verify-S5659',
    name: 'Sonar: Verify JWT Decode',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:python/literal-or-new-object-identity-S5796',
    name: 'Sonar: Replace `is` with `==` for literal or new object comparisons',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:python/numpy-nan-equality-S6725',
    name: 'Sonar: Replace == comparison with numpy.isnan()',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'sonar:python/remove-assertion-in-pytest-raises-S5915',
    name: 'Sonar: Moves assertions out of `pytest.raises` scope',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/safe-lxml-parser-defaults',
    name: 'Use Safe Defaults for `lxml` Parsers',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/safe-lxml-parsing',
    name: 'Use Safe Parsers in `lxml` Parsing Functions',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/harden-pyyaml',
    name: 'Replace unsafe `pyyaml` loader with `SafeLoader`',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/harden-ruamel',
    name: "Use `typ='safe'` in ruamel.yaml() Calls",
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/enable-jinja2-autoescape',
    name: 'Enable Jinja2 Autoescape',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/secure-flask-cookie',
    name: 'Use Safe Parameters in `flask` Response `set_cookie` Call',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/secure-flask-session-configuration',
    name: 'Flip Insecure `Flask` Session Configurations',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/flask-json-response-type',
    name: 'Set content type to `application/json` for `flask.make_response` with JSON data',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/sql-parameterization',
    name: 'Parameterize SQL Queries',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/limit-readline',
    name: 'Limit readline()',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/use-defusedxml',
    name: 'Use `defusedxml` for Parsing XML',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/harden-pickle-load',
    name: 'Harden `pickle.load()` against deserialization attacks',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/fix-file-resource-leak',
    name: 'Automatically Close Resources',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/upgrade-sslcontext-minimum-version',
    name: 'Upgrade SSLContext Minimum Version',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/upgrade-sslcontext-tls',
    name: 'Upgrade TLS Version In SSLContext',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/https-connection',
    name: 'Enforce HTTPS Connection for `urllib3`',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/requests-verify',
    name: 'Verify SSL Certificates for Requests.',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/django-debug-flag-on',
    name: 'Disable Django Debug Mode',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/django-session-cookie-secure-off',
    name: 'Secure Setting for Django `SESSION_COOKIE_SECURE` flag',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/django-json-response-type',
    name: 'Set content type to `application/json` for `django.http.HttpResponse` with JSON data',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/django-receiver-on-top',
    name: 'Ensure Django @receiver is the first decorator',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/replace-flask-send-file',
    name: 'Replace unsafe usage of `flask.send_file`',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/add-requests-timeouts',
    name: 'Add timeout to `requests` calls',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/subprocess-shell-false',
    name: 'Use `shell=False` in `subprocess` Function Calls',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/secure-tempfile',
    name: 'Upgrade and Secure Temp File Creation',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/jwt-decode-verify',
    name: 'Verify JWT Decode',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/secure-random',
    name: 'Secure Source of Randomness',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/bad-lock-with-statement',
    name: 'Separate Lock Instantiation from `with` Call',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/exception-without-raise',
    name: 'Ensure bare exception statements are raised',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/remove-debug-breakpoint',
    name: 'Remove Calls to `builtin` `breakpoint` and `pdb.set_trace',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/literal-or-new-object-identity',
    name: 'Replace `is` with `==` for literal or new object comparisons',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/sandbox-process-creation',
    name: 'Sandbox Process Creation',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/url-sandbox',
    name: 'Sandbox URL Creation',
    importance: 'HIGH',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/use-generator',
    name: 'Use Generator Expressions Instead of List Comprehensions',
    importance: 'LOW',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:python/use-set-literal',
    name: 'Use Set Literals Instead of Sets from Lists',
    importance: 'LOW',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:python/fix-mutable-params',
    name: 'Replace Mutable Default Parameters',
    importance: 'MEDIUM',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:python/lazy-logging',
    name: 'Convert Eager Logging to Lazy Logging',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/numpy-nan-equality',
    name: 'Replace == comparison with numpy.isnan()',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/fix-async-task-instantiation',
    name: 'Use High-Level `asyncio` API Functions to Create Tasks',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/fix-deprecated-abstractproperty',
    name: 'Replace Deprecated `abc` Decorators',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/fix-deprecated-logging-warn',
    name: 'Replace Deprecated `logging.warn`',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/remove-future-imports',
    name: 'Remove deprecated `__future__` imports',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/remove-module-global',
    name: 'Remove `global` Usage at Module Level',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/combine-startswith-endswith',
    name: 'Simplify Boolean Expressions Using `startswith` and `endswith`',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/fix-assert-tuple',
    name: 'Fix `assert` on Non-Empty Tuple Literal',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/fix-hasattr-call',
    name: 'Use `callable` builtin to check for callables',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/remove-assertion-in-pytest-raises',
    name: 'Moves assertions out of `pytest.raises` scope',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/str-concat-in-sequence-literals',
    name: 'Convert Implicit String Concat Inside Sequence into Individual Elements',
    importance: 'MEDIUM',
    result: 'NOT_FOUND',
  },
  {
    codemod_id: 'pixee:python/remove-unnecessary-f-str',
    name: 'Remove Unnecessary F-strings',
    importance: 'LOW',
    result: 'FOUND',
  },
  {
    codemod_id: 'pixee:python/django-model-without-dunder-str',
    name: 'Ensure Django Model Classes Implement a `__str__` Method',
    importance: 'LOW',
    result: 'NOT_FOUND',
  },
]

export const mapCodemodsImportanceToPRs = (pullRequest: PullRequest): PullRequestWithImportance => {
  const importance = codemodsImportance.find(codemod => codemod.name === pullRequest.title)?.importance
  return {
    ...pullRequest,
    importance: importance ? importance : 'UNKNOWN',
  }
}
