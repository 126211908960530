import { useLocalStorage } from '@uidotdev/usehooks'
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react'

export type Theme = 'light' | 'dark'

type ThemeContextType = {
  theme: Theme
  toggleTheme: () => void
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined)

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) throw new Error('useTheme must be used within a WithTheme component')
  return context
}

export const WithTheme = ({ initialTheme, children }: PropsWithChildren<{ initialTheme?: Theme }>) => {
  const [theme, setTheme] = useLocalStorage<Theme>(
    `ui-state/theme/initial-theme=${initialTheme}`,
    initialTheme || 'dark'
  )
  const toggleTheme = useCallback(() => setTheme(theme => (theme === 'dark' ? 'light' : 'dark')), [setTheme])

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', theme)
  }, [theme])

  const contextValue = useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme])

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>
}
