import { Area, AreaChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import { match } from 'ts-pattern'
import { colors } from '../main.css.ts'
import { useTheme } from '../utils/higher-order-components/with-theme'
import * as styles from './prs-area-chart.css.ts'

export type PRsAreaChartData = {
  name: string
  merged: number
  opened: number
  autoClosed: number
  closed: number
  isAnalysisRun?: boolean
}[]

type PRsAreaChartProps = {
  data: PRsAreaChartData
  areaType?: 'monotone' | 'natural'
  isPending: boolean
}

export function PRsAreaChart({ data, areaType = 'monotone', isPending }: PRsAreaChartProps) {
  const { theme } = useTheme()

  // Calculated manually using https://www.npmjs.com/package/css-gradient-angle-to-svg-gradient-coordinates
  const angleODegree = { x1: 0.5, y1: 1, x2: 0.5, y2: 0 }

  return (
    <div className={styles.container}>
      {isPending ? (
        <></>
      ) : (
        <div className={styles.analysesContainer}>
          <span>Analyses:</span>
        </div>
      )}
      <ResponsiveContainer
        style={{ transform: 'translateX(+4.5%)' }}
        width={'92%'}
        height={352 + 17}
        className={isPending ? styles.graphLoadingBackground : ''}
      >
        {isPending ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="1508" height="120" fill="none" viewBox="0 0 1508 120">
            <path
              stroke="url(#paint0_linear_3320_9881)"
              strokeLinecap="round"
              strokeWidth="2"
              d="M1 118.016c34.667-18.022 123.209-65.374 208-70.14 182-10.228 326 70.14 482 70.14 136 0 289.32-81.468 428-108.131 152-29.225 288 23.38 388 18.996"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_3320_9881"
                x1="42"
                x2="1466"
                y1="118"
                y2="118"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#AEABB6"></stop>
                <stop offset="0.52" stopColor="#AEABB6" stopOpacity="0.1"></stop>
                <stop offset="1" stopColor="#AEABB6"></stop>
              </linearGradient>
            </defs>
          </svg>
        ) : (
          <AreaChart data={data} margin={{ top: 30 + 22, right: 50, left: 50, bottom: 0 }}>
            <defs>
              <linearGradient id="gradientOpen" {...angleODegree}>
                <stop stopColor={theme === 'dark' ? '#28807E' : '#53D1C2'} stopOpacity="0.8" />
                <stop offset="1" stopColor={theme === 'dark' ? '#53D1C2' : '#28807E'} />
              </linearGradient>
              <linearGradient id="gradientMerged" {...angleODegree}>
                <stop stopColor="#6000FE" />
                <stop offset="1" stopColor="#6000FE" stopOpacity="0.5" />
              </linearGradient>
              <linearGradient id="gradientAutoClosed" {...angleODegree}>
                <stop stopColor="#BA95F9" />
                <stop offset="1" stopColor="#BA95F9" stopOpacity="0.5" />
              </linearGradient>
              <linearGradient id="gradientClosed" {...angleODegree}>
                <stop stopColor="#8368B4" />
                <stop offset="1" stopColor="#8368B4" stopOpacity="0.5" />
              </linearGradient>
            </defs>
            <Area
              type={areaType}
              stackId="1"
              dataKey="opened"
              fill="url(#gradientOpen)"
              fillOpacity="1"
              strokeWidth={0}
              activeDot={CustomActiveDot}
            />
            <Area
              type={areaType}
              stackId="1"
              dataKey="merged"
              fill="url(#gradientMerged)"
              fillOpacity="1"
              strokeWidth={0}
              activeDot={CustomActiveDot}
            />
            <Area
              type={areaType}
              stackId="1"
              dataKey="autoClosed"
              fill="url(#gradientAutoClosed)"
              fillOpacity="1"
              strokeWidth={0}
              activeDot={CustomActiveDot}
            />
            <Area
              type={areaType}
              stackId="1"
              dataKey="closed"
              fill="url(#gradientClosed)"
              fillOpacity="1"
              strokeWidth={0}
              activeDot={CustomActiveDot}
            />
            {data
              .filter(data => data.isAnalysisRun)
              .map(({ name }) => {
                return (
                  <ReferenceLine
                    key={name}
                    x={name}
                    // label={CustomLabel(name)}
                    stroke={theme === 'dark' ? colors.neutral.neutral10 : colors.neutral.neutral90}
                    strokeWidth={0.5}
                    strokeDasharray="4 4"
                  />
                )
              })}
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              className={styles.xAxis}
              tick={{ fill: theme === 'dark' ? colors.neutral.neutral30 : colors.neutral.neutral90 }}
              tickSize={16}
              minTickGap={-20}
            />
            <Tooltip
              // @ts-ignore
              cursor={<CustomCursor />}
              // @ts-ignore
              content={CustomTooltip}
            />
          </AreaChart>
        )}
      </ResponsiveContainer>
    </div>
  )
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        {payload
          .filter(({ name }) => name !== 'autoClosed')
          .sort((a, b) => {
            const order = { closed: 1, autoClosed: 2, merged: 3, opened: 4 }
            return order[a.name] - order[b.name]
          })
          .map(({ name, value }: { name: 'closed' | 'autoClosed' | 'merged' | 'opened'; value: number }) =>
            match(name)
              .with('closed', () => (
                <p key={name} className={value === 0 ? styles.coloredSquare.empty : styles.coloredSquare[name]}>
                  {value} closed
                </p>
              ))
              .with('autoClosed', () => (
                <p key={name} className={value === 0 ? styles.coloredSquare.empty : styles.coloredSquare[name]}>
                  {value} auto-closed
                </p>
              ))
              .with('merged', () => (
                <p key={name} className={value === 0 ? styles.coloredSquare.empty : styles.coloredSquare[name]}>
                  {value} fixes merged
                </p>
              ))
              .with('opened', () => (
                <p key={name} className={value === 0 ? styles.coloredSquare.empty : styles.coloredSquare[name]}>
                  {value} opened
                </p>
              ))
              .exhaustive()
          )}
      </div>
    )
  }

  return null
}

const CustomCursor = ({ points: [bottomPoint, topPoint] }) => (
  <path
    className={styles.cursor}
    pointerEvents="none"
    d={`M${bottomPoint.x},${bottomPoint.y}L${topPoint.x},${topPoint.y}`}
    strokeWidth={1}
  />
)

const CustomActiveDot = ({ cx, cy }) => <circle cx={cx} cy={cy} r="4" className={styles.activeDot} />
