import { utilities } from '../main.css.ts'
import * as styles from './table-body-skeleton.css.ts'

type TableBodySkeletonProps = {
  columnCount: number
  rowCount: number
  rowHeightInPixels: number
  label?: string
}

export function TableBodySkeleton({
  columnCount,
  rowCount,
  rowHeightInPixels,
  label = 'Loading table data...',
}: TableBodySkeletonProps) {
  return (
    <tbody>
      {Array.from({ length: rowCount }, (_, rowIndex) => (
        <tr key={rowIndex} className={styles.skeletonRow}>
          {Array.from({ length: columnCount }, (_, colIndex) => (
            <td key={colIndex} style={{ height: `${rowHeightInPixels}px` }}>
              {rowIndex === 0 && colIndex === 0 && (
                <span className={utilities.visuallyHidden} role="status" aria-label={label} aria-live="polite">
                  {label}
                </span>
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}
