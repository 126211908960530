import * as Portal from '@radix-ui/react-portal'
import * as styles from './toasts-container.css.ts'

export const ToastsContainer = ({ children }) => {
  return (
    <Portal.Root asChild>
      <div className={styles.toastsContainer}>{children}</div>
    </Portal.Root>
  )
}
